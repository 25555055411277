
export class SectionWrapper {
  constructor(public item: Parse.Object) {}

  id(): string {
    return this.item.id;
  }

  title(): string | undefined {
    return this.item.get('title');
  }

  image(): Parse.File | undefined {
    const image = this.item.get('image') as Parse.File | undefined;
    if (!image) { return; }
    return image;
  }

  setImage(url: string | undefined, bytes: number[] | undefined, name: string | undefined): void {
    if (url !== this.image()?.url()) {
      if (url && bytes) {
        const file = new Parse.File(`${name ?? 'image'}`, bytes);
        this.item.set('image', file);
      } else {
        this.item.unset('image');
      }
    }
  }

  sortOrder(): number {
    return this.item.get('sortOrder');
  }

  setSortOrder(value: number): void {
    this.item.set('sortOrder', value);
  }

  isDeleted(): boolean {
    return this.item.get('deletedAt') != null;
  }

  items(): Parse.Object[] {
    return this.item.get('items');
  }
}
