import 'bootstrap/dist/css/bootstrap.min.css';
import Parse from 'parse';
import React, { lazy, Suspense } from 'react';
import { Redirect, Route, RouteProps, BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.css';
import { DataRepository, DataRepositoryListener } from './Common';
import { LoginContainer } from './containers/LogIn';
import { ItemWrapper } from './models/Item';
import { SectionWrapper } from './models/Section';
import { initializeParse } from './Parse';
import { isLoggedIn } from './Parse/session';
import { CopiedToastContainer } from './Toasts/Toast';

const Sections = lazy(() => import('./views/Section'));
const SectionComp = lazy(() => import('./views/Section/form'));
const Items = lazy(() => import('./views/Item'));
const ItemComp = lazy(() => import('./views/Item/form'));
const SyncData = lazy(() => import('./views/SyncData'));

const LOGIN_PATH = '/login';

export const SafeRoute: React.FC<RouteProps> = (props) => {
  return (<Route
    exact={props.exact}
    path={props.path}
  >
    <>
      {isLoggedIn() ? props.children :  <Redirect to={LOGIN_PATH} /> }
    </>

  </Route>);
};

class App extends React.Component implements DataRepository {
  private dataRepositoryListeners: Set<DataRepositoryListener> = new Set();
  private itemWrappers: ItemWrapper[] | undefined = undefined;
  private sectionWrappers: SectionWrapper[] | undefined = undefined;

  constructor(props: Readonly<Record<string, unknown>>) {
    super(props);
    initializeParse();

    const itemQuery = new Parse.Query(Parse.Object.extend('Item'));
    itemQuery.include('ItemSection');
    itemQuery.ascending('sortOrder');
    itemQuery.limit(100_000);

    const sectionQuery = new Parse.Query(Parse.Object.extend('ItemSection'));
    sectionQuery.ascending('sortOrder');
    sectionQuery.limit(100_000);

    // Note that we're also including Items with non-null `deletedAt`, this is so that we can
    // force user not to create a new Item with an `itemId` that was previously used in a
    // deleted Item.
    void itemQuery.find().then((items: Parse.Object[]) => {
      const wrappers = 
        items.map((item: Parse.Object) => new ItemWrapper(item))
          .filter((item) => !item.isDeleted());
      const hiddenWrappers = 
        items.map((item: Parse.Object) => new ItemWrapper(item))
          .filter((item) => item.isDeleted());

      hiddenWrappers.forEach((wrapper) => {
        if (wrapper.isDeleted()) {
          wrapper.setSortOrder(-1);
        }
      });

      const allWrappers = [...wrappers, ...hiddenWrappers];
      this.itemWrappers = allWrappers;
      this.dataRepositoryListeners.forEach((listener) => {
        listener.onItemsLoaded(allWrappers);
      });
    });

    void sectionQuery.find().then((sections: Parse.Object[]) => {
      const wrappers = sections
        .map((section: Parse.Object) => new SectionWrapper(section))
        .filter((wrapper) => !wrapper.isDeleted());
      const hiddenWrappers = sections
        .map((section: Parse.Object) => new SectionWrapper(section))
        .filter((wrapper) => wrapper.isDeleted());

      hiddenWrappers.forEach((wrapper) => {
        if (wrapper.isDeleted()) {
          wrapper.setSortOrder(-1);
        }
      });

      const allWrappers = [...wrappers, ...hiddenWrappers];
      this.sectionWrappers = allWrappers;
      this.dataRepositoryListeners.forEach((listener) => {
        listener.onSectionsLoaded(allWrappers);
      });
    });
  }

  getItems(): (ItemWrapper[] | undefined) {
    return this.itemWrappers;
  }

  getSections(): SectionWrapper[] | undefined {
    return this.sectionWrappers;
  }

  //------------------------------------------------------
  // CRUD methods for Sections
  //
  updateSection(sectionWrapper: SectionWrapper, callback: (error: unknown | undefined) => void) {
    // First, ensure `sectionWrapper` is in `this.sectionWrappers`
    const sectionWrappers = this.sectionWrappers;
    if (!sectionWrappers || !sectionWrappers.find((w) => w === sectionWrapper)) {
      callback({ message: 'Non-existent Item', code: 101 });
      return;
    }

    const item = sectionWrapper.item;
    initializeParse();
    item.save().then(
      (object: any) => {
        callback(undefined);
        this.dataRepositoryListeners.forEach((listener) => {
          listener.onSectionUpdated(sectionWrapper, sectionWrappers);
        });
      },
      (error: any) => {
        callback(error);
      }
    );
  }

  createSection(sectionWrapper: SectionWrapper, callback: (error: unknown | undefined) => void) {
    // First, ensure `itemWrapper` is not in `this.itemWrappers`
    if (this.sectionWrappers?.find((wrap) => wrap === sectionWrapper)) {
      callback({ message: 'Item already exists', code: 101 });
      return;
    }

    const item = sectionWrapper.item;
    initializeParse();
    item.save().then(
      (object: unknown) => {
        const sectionWrappers = this.sectionWrappers || [];
        sectionWrappers.push(sectionWrapper);
        if (!this.sectionWrappers) {
          this.sectionWrappers = sectionWrappers;
        }
        callback(undefined);
        this.dataRepositoryListeners.forEach((listener) => {
          listener.onSectionCreated(sectionWrapper, sectionWrappers);
        });
      },
      (error: unknown) => {
        callback(error);
      }
    );
  }

  deleteSection(sectionWrapper: SectionWrapper, callback: (error: unknown | undefined) => void) {
    // First, ensure `sectionWrapper` is in `this.sectionWrappers`
    const sectionWrappers = this.sectionWrappers;
    if (!sectionWrappers || !sectionWrappers.find((w) => w === sectionWrapper)) {
      callback({ message: 'Non-existent Item', code: 101 });
      return;
    }

    const item = sectionWrapper.item;
    item.set('deletedAt', new Date());
    item.set('sortOrder', -1);
    initializeParse();
    item.save().then(
      (object: unknown) => {
        callback(undefined);
        this.dataRepositoryListeners.forEach((listener) => {
          listener.onSectionDeleted(sectionWrapper, sectionWrappers);
        });
      },
      (error: unknown) => {
        callback(error);
      }
    );
  }

  undeleteSection(sectionWrapper: SectionWrapper, newSortOrder: number, callback: (error: unknown | undefined) => void) {
    // First, ensure `sectionWrapper` is in `this.sectionWrappers`
    const sectionWrappers = this.sectionWrappers;
    if (!sectionWrappers || !sectionWrappers.find((w) => w === sectionWrapper)) {
      callback({ message: 'Non-existent Item', code: 101 });
      return;
    }

    const item = sectionWrapper.item;
    item.unset('deletedAt');
    item.set('sortOrder', newSortOrder);
    initializeParse();
    item.save().then(
      (object: unknown) => {
        callback(undefined);
        this.dataRepositoryListeners.forEach((listener) => {
          listener.onSectionUndeleted(sectionWrapper, sectionWrappers);
        });
      },
      (error: unknown) => {
        callback(error);
      }
    );
  }


  //------------------------------------------------------
  // CRUD methods for Items
  //

  updateItem(itemWrapper: ItemWrapper, callback: (error: unknown | undefined) => void) {
    // First, ensure `itemWrapper` is in `this.itemWrappers`
    const itemWrappers = this.itemWrappers;
    if (!itemWrappers || !itemWrappers.find((w) => w === itemWrapper)) {
      callback({ message: 'Non-existent Item', code: 101 });
      return;
    }

    const item = itemWrapper.item;
    initializeParse();
    item.save().then(
      (object: any) => {
        callback(undefined);
        this.dataRepositoryListeners.forEach((listener) => {
          listener.onItemUpdated(itemWrapper, itemWrappers);
        });
      },
      (error: any) => {
        callback(error);
      }
    );
  }

  createItem(itemWrapper: ItemWrapper, callback: (error: unknown | undefined) => void) {
    // First, ensure `itemWrapper` is not in `this.itemWrappers`
    if (this.itemWrappers?.find((wrap) => wrap === itemWrapper)) {
      callback({ message: 'Item already exists', code: 101 });
      return;
    }

    const item = itemWrapper.item;
    initializeParse();
    console.log('Creating item: ', itemWrapper.item);
    
    item.save().then(
      (object: unknown) => {
        const itemWrappers = this.itemWrappers || [];
        itemWrappers.push(itemWrapper);
        if (!this.itemWrappers) {
          this.itemWrappers = itemWrappers;
        }
        callback(undefined);
        this.dataRepositoryListeners.forEach((listener) => {
          listener.onItemCreated(itemWrapper, itemWrappers);
        });
      },
      (error: unknown) => {
        callback(error);
      }
    );
  }

  deleteItem(itemWrapper: ItemWrapper, callback: (error: unknown | undefined) => void) {
    // First, ensure `itemWrapper` is in `this.itemWrappers`
    const itemWrappers = this.itemWrappers;
    if (!itemWrappers || !itemWrappers.find((w) => w === itemWrapper)) {
      callback({ message: 'Non-existent Item', code: 101 });
      return;
    }

    const item = itemWrapper.item;
    item.set('deletedAt', new Date());
    initializeParse();
    item.save().then(
      (object: unknown) => {
        callback(undefined);
        this.dataRepositoryListeners.forEach((listener) => {
          listener.onItemDeleted(itemWrapper, itemWrappers);
        });
      },
      (error: unknown) => {
        callback(error);
      }
    );
  }

  undeleteItem(itemWrapper: ItemWrapper, newSortOrder: number, callback: (error: unknown | undefined) => void) {
    // First, ensure `itemWrapper` is in `this.itemWrappers`
    const itemWrappers = this.itemWrappers;
    if (!itemWrappers || !itemWrappers.find((w) => w === itemWrapper)) {
      callback({ message: 'Non-existent Item', code: 101 });
      return;
    }

    const item = itemWrapper.item;
    item.unset('deletedAt');
    item.set('sortOrder', newSortOrder);
    initializeParse();
    item.save().then(
      (object: unknown) => {
        callback(undefined);
        this.dataRepositoryListeners.forEach((listener) => {
          listener.onItemUndeleted(itemWrapper, itemWrappers);
        });
      },
      (error: unknown) => {
        callback(error);
      }
    );
  }

  registerListener(listener: DataRepositoryListener) {
    this.dataRepositoryListeners.add(listener);
  }

  unregisterListener(listener: DataRepositoryListener) {
    this.dataRepositoryListeners.delete(listener);
  }

  render() {
    return (
      <Router>
        <Suspense fallback={<div />}>
          <Switch>
            <SafeRoute
              exact
              path="/"
            >
              <Sections dataRepository={this} />
            </SafeRoute>
            <SafeRoute
              exact
              path="/sections"
            >
              <Sections dataRepository={this} />
            </SafeRoute>
            <SafeRoute
              exact
              path="/sections/:id"
            >
              <SectionComp dataRepository={this} />
            </SafeRoute>
            <SafeRoute
              exact
              path="/items"
            >
              <Items dataRepository={this} />
            </SafeRoute>
            <SafeRoute
              exact
              path="/items/:id"
            >
              <ItemComp dataRepository={this} />
            </SafeRoute>
            <SafeRoute
              exact
              path="/sync-data"
            >
              <SyncData />
            </SafeRoute>
            <Route
              exact
              path={LOGIN_PATH}
            ><LoginContainer /></Route>
          </Switch>
        </Suspense>
        <CopiedToastContainer />
      </Router>
    );
  }
}

export default App;
