import Parse from 'parse';

function initializeForProd() {
  Parse.initialize(
    'LVTYPhlfIkeg7jqZlOlLpLfvFExvbazQg1Kn5uzf',
    'wgIl1orwkZxBe57i7WHgc7oiaYmBFtbTE7B4Lgfs'
  );
  Parse.serverURL = 'https://api.bfnz.rhpi.com.au';
}

function initializeForStaging() {
  Parse.initialize(
    'staging-LVTYPhlfIkeg7jqZlOlLpLfvFExvbazQg1Kn5uzf',
    'wgIl1orwkZxBe57i7WHgc7oiaYmBFtbTE7B4Lgfs'
  );
  Parse.serverURL = 'https://api-staging.bfnz.rhpi.com.au';
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function initializeForLocalDevelopment() {
  Parse.initialize(
    'staging-LVTYPhlfIkeg7jqZlOlLpLfvFExvbazQg1Kn5uzf',
    'wgIl1orwkZxBe57i7WHgc7oiaYmBFtbTE7B4Lgfs'
  );
  // Parse.serverURL = 'http://localhost:1337/parse/';
  Parse.serverURL = 'https://api-staging.bfnz.rhpi.com.au';
}

export function initializeParseForDataSync() {
  // Parse should be pointed at staging for data sync.
  initializeForStaging();
  // initializeForLocalDevelopment(); // Don't commit the uncommenting of this line for local development
}

export function initializeParse() {
  // Parse should be pointed at staging for content management
  initializeForStaging();
  initializeForLocalDevelopment(); // Don't commit the uncommenting of this line for local development
}

export function initializeParseForDash():void  {
  initializeParse();
}
