import Parse from 'parse';
import { BaseModel } from './BaseModel';

export class UserModel extends BaseModel {
  constructor(public object: Parse.Object) { super(object); }

  static new(): UserModel {
    const object = new (Parse.Object.extend('User'))();
    object.set('type', 1);
    return new UserModel(object);
  }

  username(): string { return this.object.get('username'); }

  isAdmin(): boolean {
    const isAdmin = this.object.get('isAdmin') as boolean | undefined;
    return isAdmin === true;
  }
}