import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { LogInUser } from '../../Parse/session';

export const LoginContainer = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <LoginView
        isLoading={isLoading}
        onSignIn={async (username, password) => {
          setIsLoading(true);
          const { success, error } = await LogInUser(username, password);
          if (success) {
            history.push('/');
          }
          setIsLoading(false);
          return error ?? 'There was an unknown error. Try again.';
        }}
      />
    </>);
};

interface LoginViewProps {
  onSignIn: (username: string, password: string) => Promise<string>;
  isLoading: boolean;
}

export const LoginView: React.FC<LoginViewProps> = ({ onSignIn, isLoading }) => {
  const [state, setState] = useState<{ username: string; password: string }>({ username: '', password: '' });
  const [previousError, setPreviousError] = useState<string | undefined>();

  return (
    <div
      className="d-flex align-items-center justify-content-center vh-100"
    >
      <div className="border bg-white w-50 h-50 d-flex flex-column align-items-center">
        <img
          src="/bfnz-logo.png"
          height="100"
          className="d-inline-block align-top my-4"
          alt=""
        />

        <div className="w-50 px-4 d-flex flex-column align-items-center">

          <form className="w-100">
            <input
              className="form-control px-1"
              type="text"
              value={state.username}
              onChange={e => setState({ ...state, username: e.target.value })}
              id="order"
              placeholder="Username"
            />

            <input
              className="form-control px-1 mt-1"
              type="password"
              value={state.password}
              onChange={e => setState({ ...state, password: e.target.value })}
              id="order"
              placeholder="Password"
            />

            <button
              type="button"
              className="btn btn-primary mt-2 w-100"
              onClick={async () => {
                setPreviousError(undefined);
                const error = await onSignIn(state.username, state.password);
                setPreviousError(error);

              }}
              disabled={isLoading}
            >{isLoading ? 'Signing in... ' : 'Sign in'}</button>

            {previousError &&
              <div className="w-100 text-danger text-center mt-2">{previousError}</div>
            }
          </form>
        </div>
      </div>
    </div>
  );
};