import Parse from 'parse';
import { UserModel } from '../models/User';
import { initializeParseForDash } from './index';

export function CurrentUser(): UserModel | undefined {
  const user = Parse.User.current();
  if (!user) { return; }
  const model = new UserModel(user);
  return model;
}


export async function LogInUser(username: string, password: string): Promise<{success: boolean; error?: string}> {
  initializeParseForDash();
  try {
    const user = await Parse.User.logIn(username, password);
    const model = new UserModel(user);

    if (!model.isAdmin()) {
      await Parse.User.logOut();
      return { success: false, error: 'Access denied. You are not an admin.' };
    }
    return { success: true  };
  }
  catch (error) {
    // @ts-expect-error ignore this
    const msg = String(error?.message);
    return { success: false, error: msg };
  }

}

export async function LogOutUser(): Promise<void> {
  initializeParseForDash();
  await Parse.User.logOut();
}

export function isLoggedIn(): boolean {
  initializeParseForDash();
  const user = Parse.User.current();

  return user !== null && user !== undefined;
}