import Parse from 'parse';

export class BaseModel {

  constructor(public object: Parse.Object) { }

  id(): string { return this.object.id; }
  createdAt(): Date { return this.object.get('createdAt'); }

  async save(): Promise<this> {
    await this.object.save();
    return this;
  }

  delete(): void {
    this.object.set('deletedAt', new Date());
  }
}